@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

footer{
    background-color: #324D71;
}

footer .btn-main{
    --tw-shadow-color: #8EA9CC;
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}