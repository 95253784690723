.main-home{
    /* background: url('../images/home-banner.png'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* min-height: 100vh; */
}

.main-home .btn-main, nav .btn-main{
    --tw-shadow-color: #8EA9CC;
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.main-home .btn-main-alt, nav .btn-main-alt{
    --tw-shadow-color: #8EA9CC;
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.main-home .btn-main-alt:hover{
    background: #CDEAF4;
}

.main p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 30px;
}

.whychooseusx{
    background: url('https://images.unsplash.com/photo-1528731708534-816fe59f90cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bWluaW1hbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8fDA%3D&w=1000&q=80');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
