@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --main-bg: #060054;
  --main-color: #060054;
  --alt-color: #E0F1F8;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

*{
  font-family: 'Inter', sans-serif;
}

body {
  font-size: 90%;
  color: #333333;
  background: #FFFFFF;
}

.bg-main{
  background-color: var(--main-color);
}

.text-main{
  color: var(--main-color);
}

.bg-main-alt{
  background-color: var(--alt-color);
}

.text-main-alt{
  color: var(--alt-color);
}

.heading{
  font-weight: 700;
  font-size: 38px;
  line-height: 1.4;
  color: #060054;
}

nav .btn-main,nav .btn-main-alt{
  /* padding: 0.5rem 1rem 0.5rem 1rem; */
}

input,button,textarea,select{
  outline: none;
}

a{
  text-decoration: none;
}

.btn-main,.btn-main-alt,.btn-main-inverse{
  display: inline-block;
  padding: 0.85rem 1.5rem;
  border-radius: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  border-radius: 10px;
  transition: .25s;
  line-height: normal;
}

.btn-main{
  background: #FFF;
  border: 1px solid #e0f2f8;
  color: #333;
}

.btn-main:hover{
  background: #f9f9f9;
  --tw-shadow-color: #8EA9CC;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.pricing-plans .btn-main{
  background: #060054;
  border: 1px solid #060054;
  color: #FFF;
}

.pricing-plans .btn-main:hover{
  background: #030029;
  --tw-shadow-color: #8EA9CC;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.btn-download{
  --tw-shadow-color: #8EA9CC;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.btn-main-alt{
  background: #e0f2f8;
  color: var(--main-color);
  border: 1px solid #e0f2f8;
}

.btn-main-alt:hover{
  background: #CDEAF4;
  border: 1px solid #CDEAF4;
  --tw-shadow-color: #8EA9CC;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}


.btn-main-inverse{
  background: #161616;
  color: var(--main-color);
}

.btn-main-inverse:hover{
  background: #000;
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


@media only screen and (max-width: 768px) {
  .hide-in-sm{
    display: none;
  }
  .heading {
    font-size: 32px !important;
    line-height: 48px;
  }
  main{
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 920px) {
    .container{
      padding-left: 34px;
      padding-right: 34px;
    }

    nav .container{
      padding-left: 38px;
      padding-right: 38px;
    }
}