@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

.nav-title{
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
    color: white;
}

#search-navbar{
    background: #1F1F1F;
    border: 1px solid #2C2C2C;
    color: #CCC;
    outline: none;
    border-radius: 30px;
}

#search-navbar::placeholder{
    color: rgb(115,115,115)
}

.authnav-button{
    /* padding: 0.75rem 2rem 0.75rem 2rem; */
    border-radius: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.46px;
    background: transparent;
    text-transform: uppercase;
    color: #111;
    border:1px solid #111;
}

nav .nav-links a{
  font-weight: 600;
  color: #555;
}

nav .nav-links a:hover{
  color: #000;
}

.sm-toggler{
    display: none;
}

@media only screen and (max-width: 768px) {

    nav .btn-main,nav .btn-main-alt{
      margin-top: 20px;
    }

    nav .nav-links{
      display: none;
    }
    
    nav #navbar-cta{
        width: 75%;
        opacity: 1;
    }

    nav #navbar-cta{
        display: flex;
        position: fixed;
        z-index: 99;
        background-color: #17171B;
        top: 0;
        margin-left: calc(25% - 1rem);
        opacity: 1;
        width: 75%;
        height: 100vh;
        border-left: 1px solid #99999950;
        padding: 30px 25px;
    }

    nav.toggled #navbar-cta{
      --tw-shadow: 0 0px 30vw 30vw rgba(0,0,0,0.7);
      --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .toggler{
      position: absolute;
      right: 15px;
      top: 25px;
      z-index: 999;
      background: white;
      /* height: 3rem;
      width: 3rem;
      border-radius: 50%; */
    }

    .sm-toggler{
        overflow-y: scroll;
        display: flex;
        width: 100%;
        padding-bottom: 100px;
    }

    .nav-buttons{
        display: none;
    }
}